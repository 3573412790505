import React, { useEffect, useState } from "react";
import UserReportTable from "../components/UserReportTable";
import CampaignReportTable from "../components/CampaignReportTable";
import ModuleReportTable from "../components/ModuleReportTable";

const DashboardPage = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [showUserReportTable, setShowUserReportTable] = useState(false);
  const [showCampaignReportTable, setShowCampaignReportTable] = useState(false);
  const [showModuleReportTable, setShowModuleReportTable] = useState(false);

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === 'campaign') {
      setShowCampaignReportTable(true);
    } else {
      setShowCampaignReportTable(false);
    }
    if (buttonName === 'module') {
      setShowModuleReportTable(true);
    } else {
      setShowModuleReportTable(false);
    }
    if (buttonName === 'user') {
      setShowUserReportTable(true);
    } else {
      setShowUserReportTable(false);
    }
  };

  useEffect(() => {
    setShowCampaignReportTable(true);
  },[])

  return (
    <>
      <div className="w-full h-auto flex flex-row justify-start items-start gap-3 mb-6">
        <button
          className={`px-4 py-2 font-semibold text-gray-500 rounded-lg ${
            activeButton === 'campaign' ? 'bg-gray-300 border-0' : 'border-2 border-gray-300'
          }`}
          onClick={() => handleClick('campaign')}
        >
          Campaign Report
        </button>
        <button
          className={`px-4 py-2 font-semibold text-gray-500 rounded-lg ${
            activeButton === 'module' ? 'bg-gray-300 border-0' : 'border-2 border-gray-300'
          }`}
          onClick={() => handleClick('module')}
        >
          Module Report
        </button>
        <button
          className={`px-4 py-2 font-semibold text-gray-500 rounded-lg ${
            activeButton === 'user' ? 'bg-gray-300 border-0' : 'border-2 border-gray-300'
          }`}
          onClick={() => handleClick('user')}
        >
          User Report
        </button>
      </div>
      {showCampaignReportTable && <CampaignReportTable />}
      {showModuleReportTable && <ModuleReportTable />}
      {showUserReportTable && <UserReportTable />}
    </>
  );
};

export default DashboardPage;

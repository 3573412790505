import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosConfig";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generatePassword, maskEmail } from '../../utils/helperService';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const GroupLeaderOtp = () => {
    const navigate = useNavigate()
    const [showPage, setShowPage] = useState(false);
    const [otpCode, setOtpCode] = useState("");
    const [email, setEmail] = useState("");
    const [userNotFound, setUserNotFound] = useState(false);
    const [stage2, setStage2] = useState(false);
    const [alreadySet, setalreadySet] = useState(false);


    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleNewPwd = async () => {
        if (password === confirmPassword) {
            try {
                const resp = await axiosInstance.put("/groupleaders/set-password", { email, password });
                if (resp.data.success) {
                    toast.success('Password set with success!');
                    navigate('/groupleader_login');
                } else {
                    toast.warn('Failed to set password');
                }
            } catch (error) {
                console.error('Error setting password:', error);
                toast.error('An error occurred while setting the password. Please try again.');
            }
        } else {
            toast.warn('Passwords do not match');
        }
    };

    const validateGroupLeader = async (email) => {
        try {
            const resp = await axiosInstance.get(`/groupleaders/details/${email}`);
            if (resp.data.status === 404) {
                setShowPage(false);
                setUserNotFound(true);
            } else if (resp.data.status === 200) {
                if (resp.data.data.pwdset === false || resp.data.data.pwdset === null) {
                    setShowPage(true);
                    setUserNotFound(false);
                } else {
                    setShowPage(false);
                    setalreadySet(true)
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleVerifyAccount = async () => {
        try {
            if (otpCode.length === 6) {
                const response = await axiosInstance.post('/otp/verify-otp', { email, otpCode });
                if (response.data.success) {
                    toast.success("OTP Verified!");
                    setStage2(true);
                } else {
                    toast.error(response.data.message || "Incorrect OTP!");
                }
            } else {
                toast.warn("Enter valid OTP code!");
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            toast.error("An error occurred while verifying OTP. Please try again later.");
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const emailParam = urlParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
            validateGroupLeader(emailParam);
        }
    }, [email]);

    const generatePwd = () => {
        let pwd = generatePassword(7)
        setPassword(pwd)
        setConfirmPassword(pwd)
    }

    return (
        <>
            <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12">
                <ToastContainer />
                {showPage && (
                    <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
                        {!stage2 && (
                            <div className="mx-auto flex w-full max-w-md flex-col gap-5">
                                <div className="flex flex-col items-center gap-5 justify-center text-center">
                                    <div className="font-semibold text-3xl">
                                        <p>Email Verification</p>
                                    </div>
                                    <div className="flex flex-row text-sm font-medium text-gray-400">
                                        <p>We have sent an OTP code to your email {email && maskEmail(email)}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                                        <div className="w-full h-16 ">
                                            <input onChange={(e) => setOtpCode(e.target.value)} maxLength={6} className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700" type="text" name="" id="" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div>
                                            <button onClick={handleVerifyAccount} className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-blue-700 border-none text-white text-md font-semibold shadow-sm">
                                                Verify Account
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {stage2 && (
                            <div className="mx-auto flex w-full max-w-md flex-col gap-5">
                                <div className="flex flex-col items-center gap-5 justify-center text-center">
                                    <div className="font-semibold text-2xl">
                                        <p>Set a New Password</p>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full justify-center items-center gap-5">
                                    <div className="w-full">
                                        <TextField
                                            fullWidth
                                            type={showPassword ? 'text' : 'password'}
                                            label="Password"
                                            variant="outlined"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            fullWidth
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            label="Confirm Password"
                                            variant="outlined"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <button onClick={generatePwd} className='px-3 py-2 rounded-md bg-blue-950 text-white font-semibold'>Generate Password</button>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <button onClick={handleNewPwd} className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-blue-700 border-none text-white text-md font-semibold shadow-sm">
                                            Set New Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {userNotFound && (
                    <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
                        <div className="mx-auto flex w-full text-center max-w-md flex-col gap-5">
                            <h1 className="font-semibold text-center text-1xl">User not found!</h1>
                        </div>
                    </div>
                )}
                {alreadySet && (
                    <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
                        <div className="mx-auto flex w-full text-center max-w-md flex-col gap-5">
                            <h1 className="font-semibold text-center text-1xl">Password already set!</h1>
                        </div>
                    </div>
                )}
            </div>
        </>)
}

export default GroupLeaderOtp;
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Contributor from './sections/Contributor';
import GroupLeader from './sections/GroupLeader';
import Subscriber from './sections/Subscriber';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormLabel, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Radio, RadioGroup } from '@mui/material';
import axiosInstance from '../../utils/axiosConfig';
import { useAuth } from "../../AuthContext"
import { GoPhishAccountAPIKey, GoPhishPublicURL } from '../../utils/constants';
import axios from 'axios';

const GroupManagmentPage = () => {

  const { currentUser } = useAuth()

  const [coursesData, setCoursesData] = useState([]);
  const getAllCourses = async () => {
    try {
      const result = await axiosInstance.get(`/purchased_courses/all/${currentUser.email}`);
      const courses = result.data.data
      setCoursesData(courses)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllCourses()
  }, [])

  const [AllGroups, setAllGroups] = useState([]);
  const [SelectedGroupName, setSelectedGroupName] = useState("");
  const [SelectedGroupId, setSelectedGroupId] = useState("");
  const [addgroupmodal, setaddgroupmodal] = useState(false);
  const [SelectedCourseTitle, setSelectedCourseTitle] = useState("");
  const [SelectedCourseId, setSelectedCourseId] = useState("");

  const [formData, setFormData] = useState({
    groupname: "",
    signinType: "with_pwd",
    gl_firstname: "",
    gl_lastname: "",
    gl_email: ""
  });

  const handleAttachCoursetoGroup = async () => {
    try {
      const response = await axiosInstance.post("/attach/course_to_group", {
        "group_id": SelectedGroupId,
        "course_id": SelectedCourseId,
        "email": currentUser.email
      })
      if (response.data.success) {
        toast.success("Course attached to group successfully!")
        getAllCourses()
      }
      else if (!response.data.success) {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  }

  const fetchAllGroups = async () => {
    try {
      const resp = await axiosInstance.get(`/groups/all/${currentUser.email}`)
      setAllGroups(resp.data.groups)
    } catch (error) {
      console.log("Error fetching all groups!")
    }
  }

  useEffect(() => {
    fetchAllGroups()
  }, [currentUser])

  const handleAddGroup = () => {
    setaddgroupmodal(true);
  }

  const handleCloseGroupModal = () => {
    setaddgroupmodal(false);
  }

  // const handleGroupSubmit = async () => {
  //   try {
  //     const resp = await axiosInstance.post("/groups/add", {
  //       name: formData.groupname,
  //       signin_type: formData.signinType,
  //       creator_role: currentUser.role,
  //       creator_email: currentUser.email
  //     })
  //     await axios.post(
  //       `${GoPhishPublicURL}/api/groups/?api_key=${GoPhishAccountAPIKey}`,
  //       {
  //         "name": formData.groupname,
  //         "targets": [
  //           {
  //             "email": currentUser.email,
  //             "first_name": currentUser.firstname,
  //             "last_name": currentUser.lastname,
  //             "position": ""
  //           }
  //         ]
  //       }
  //     );
  //     if (resp.status === 201) {
  //       toast.success('Group created successfully');
  //       handleCloseGroupModal();
  //       fetchAllGroups();
  //     }
  //   } catch (error) {
  //     console.error('Failed to create group:', error);
  //     toast.error('Failed to create group');
  //   }
  // }

  const handleGroupSubmit = async () => {
    try {
      const group_data = await axios.post(
        `${GoPhishPublicURL}/api/groups/?api_key=${GoPhishAccountAPIKey}`,
        {
          "name": formData.groupname,
          "targets": [
            {
              "email": formData.gl_email,
              "first_name": formData.gl_firstname,
              "last_name": formData.gl_lastname,
              "position": "Group Leader"
            }
          ]
        }
      );
      const resp = await axiosInstance.post("/groups/add", {
        "name": formData.groupname,
        "signin_type": formData.signinType,
        "creator_role": currentUser.role,
        "creator_email": currentUser.email,
        "gophish_groupid": group_data.data.id
      })
      await axiosInstance.post(`/groupleaders/add`, {
        "firstname": formData.gl_firstname,
        "lastname": formData.gl_lastname,
        "email": formData.gl_email,
        "group_id": resp.data.ids.lms_groupid,
        "currentUser": currentUser,
      });
      if (resp.status === 201) {
        toast.success('Group created successfully');
        handleCloseGroupModal();
        handleCloseAddGLModal();
        fetchAllGroups();
      }
    } catch (error) {
      console.error('Failed to create group:', error);
      toast.error('Failed to create group');
    }
  }

  const [openAddGLModal, setopenAddGLModal] = useState(false);

  const handleOpenAddGLModal = () => {
    setopenAddGLModal(true);
  }
  const handleCloseAddGLModal = () => {
    setopenAddGLModal(false);
  }

  const [ListOfAttachedCourses, setListOfAttachedCourses] = useState([])
  const getAttachedCoursesOfGroup = async (id) => {
    try {
      const data = await axiosInstance.get(`/get_attached_courses/${id}`)
      setListOfAttachedCourses(data?.data?.data)
    } catch (error) {
      console.log("error getting attached courses to this selected group: ", error)
    }
  }
  useEffect(() => {
    if (SelectedGroupId) {
      getAttachedCoursesOfGroup(SelectedGroupId);
    }
  }, [SelectedGroupId])

  return (
    <>
      <div className="w-full min-h-screen flex flex-col bg-[#FAFAFA] justify-start items-center">
        <ToastContainer />

        <div className="w-[90%] flex flex-row gap-5 justify-between items-center">
          <h1 className="text-[#00486F] font-bold text-[30px] mt-5 mb-5">Groups Management</h1>
          <Button variant="outlined" onClick={handleAddGroup}>
            Add Group
          </Button>
        </div>

        <div className='w-[90%] mb-[20px] flex flex-row justify-start gap-5 items-center'>
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="groups">Select Group:</InputLabel>
            <Select
              labelId="groups"
              id="groups"
              value={SelectedGroupName}
              label="groups"
              onChange={(e, child) => {
                setSelectedGroupId(child.props.id);
                setSelectedGroupName(e.target.value);
              }}
            >
              {AllGroups && AllGroups?.map((group, index) => (
                <MenuItem id={group.id} key={index} value={group.name}>{group.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {SelectedGroupId && (<FormControl sx={{ width: "30%" }}>
            <InputLabel id="courses">Select Course:</InputLabel>
            <Select
              labelId="courses"
              id="courses"
              value={SelectedCourseTitle}
              label="courses"
              onChange={(e, child) => {
                setSelectedCourseId(child.props.id);
                setSelectedCourseTitle(e.target.value);
              }}
            >
              {coursesData && coursesData?.map((course, index) => (
                <MenuItem id={course.courseDetails.id} key={index} value={course.courseDetails.title}>{course.courseDetails.title} ({course.seats})</MenuItem>
              ))}
            </Select>
          </FormControl>)}
          {SelectedCourseId && (
            <Button onClick={handleAttachCoursetoGroup} variant="outlined">Attach Course</Button>
          )}
        </div>

        {SelectedGroupId && (
          <div className={`w-[90%] flex flex-col gap-5 mt-5 mb-5 justify-center items-start bg-white p-6 rounded-lg transition-all ease-in-out delay-500 shadow-md`}>
            <h6 className="text-lg font-semibold text-gray-800">Attached courses are listed below:</h6>
            {ListOfAttachedCourses.length > 0 &&
              ListOfAttachedCourses.map((course, index) => (
                <p
                  key={index}
                  className="w-full bg-gray-50 p-4 rounded-lg shadow-md border border-gray-200 hover:bg-gray-100 transition-colors duration-200 flex flex-col items-start gap-2"
                >
                  <span className="text-sm text-gray-500 font-semibold">
                    Id: <span className="text-gray-800">{course.id}</span>
                  </span>
                  <span className="text-sm text-gray-500 font-semibold">
                    Title: <span className="text-gray-800">{course.title}</span>
                  </span>
                </p>
              ))}
            {ListOfAttachedCourses.length === 0 &&
              (
                <p
                  className="w-full bg-gray-50 p-4 rounded-lg shadow-md border border-gray-200 hover:bg-gray-100 transition-colors duration-200 flex flex-col items-start gap-2"
                >
                  No Attached Courses.
                </p>
              )}
          </div>
        )}

        <Contributor />
        <GroupLeader g_id={SelectedGroupId} />
        <Subscriber g_id={SelectedGroupId} />

        <Dialog
          open={addgroupmodal}
          onClose={handleCloseGroupModal}
          fullWidth
        >
          <DialogTitle
            sx={{ marginBottom: "20px", backgroundColor: "#00486f", color: "white", fontWeight: "bold" }}>Add Group</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="groupname"
              name="groupname"
              label="Group Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.groupname}
              onChange={(e) => setFormData({ ...formData, groupname: e.target.value })}
            />
          </DialogContent>
          <DialogContent>
            <FormLabel id="signinType">Select Signin Type:</FormLabel>
            <RadioGroup
              aria-labelledby="signinType"
              defaultValue="with_pwd"
              name="signinType"
              value={formData.signinType}
              onChange={(e) => setFormData({ ...formData, signinType: e.target.value })}
            >
              <FormControlLabel value="pwd_less" control={<Radio />} label="Password Less" />
              <FormControlLabel value="with_pwd" control={<Radio />} label="With Password" />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseGroupModal}>Cancel</Button>
            <Button onClick={handleOpenAddGLModal}>Add</Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={openAddGLModal}
          onClose={handleCloseAddGLModal}
          fullWidth
        >
          <DialogTitle
            sx={{ marginBottom: "20px", backgroundColor: "#00486f", color: "white", fontWeight: "bold" }}>Add Group Leader</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_firstname"
              name="gl_firstname"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.gl_firstname}
              onChange={(e) => setFormData({ ...formData, gl_firstname: e.target.value })}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_lastname"
              name="gl_lastname"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.gl_lastname}
              onChange={(e) => setFormData({ ...formData, gl_lastname: e.target.value })}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_email"
              name="gl_email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={formData.gl_email}
              onChange={(e) => setFormData({ ...formData, gl_email: e.target.value })}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseAddGLModal}>Cancel</Button>
            <Button onClick={handleGroupSubmit}>Add</Button>
          </DialogActions>
        </Dialog>


      </div>
    </>
  );
};

export default GroupManagmentPage;

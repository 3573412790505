import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import AdminLoginPage from './web/pages/AdminLoginPage';
import NotFoundPage from './web/pages/NotFoundPage';

import WebLayout from './web/web-layout';

// Dashboard and Pages for admin
import DashLayout from './dashboard/dash-layout';
import DashboardPage from './dashboard/pages/DashboardPage';
import GroupManagmentPage from './dashboard/pages/GroupManagmentPage';
import UploadCourse from './dashboard/pages/UploadCourse';
import AdminBuyNewLicense from "./dashboard/pages/BuyNewLicense"
import AdminMyCart from "./dashboard/pages/MyCart"
import CourseDetails from './dashboard/pages/CourseDetails';
import Campaigns from './dashboard/pages/Campaigns';

// otps and login pages
import ContribOtp from './web/pages/ContribOtp';
import ContribLoginPage from './web/pages/ContribLoginPage';
import GroupLeaderOtp from './web/pages/GroupLeaderOtp';
import GroupLeaderLoginPage from './web/pages/GroupLeaderLoginPage';
import SubscriberOtp from './web/pages/SubscriberOtp';
import SubscriberLoginPage from './web/pages/SubscriberLoginPage';

// Dashboards and Pages for users except admin
import ContribDashLayout from "./contrib_dashboard/dash-layout"
import ContribDashPage from "./contrib_dashboard/pages/DashboardPage"
import ContribGroupManagmentPage from "./contrib_dashboard/pages/GroupManagmentPage"
import GroupLeaderDashLayout from "./groupleader_dashboard/dash-layout"
import GroupLeaderDashPage from "./groupleader_dashboard/pages/DashboardPage"
import SubsDashLayout from "./subscriber_dashboard/dash-layout"
import SubsDashPage from "./subscriber_dashboard/pages/DashboardPage"
import GroupLeaderOTPLogin from './web/pages/pwdlessForms/GroupLeaderOTPLogin';
import SubscriberOTPLogin from './web/pages/pwdlessForms/SubscriberOTPLogin';
import BuyNewLicense from './contrib_dashboard/pages/BuyNewLicense';
import MyCart from './contrib_dashboard/pages/MyCart';
import GLCourses from './groupleader_dashboard/pages/Courses';
import SCourses from './subscriber_dashboard/pages/Courses';
import ViewCourse from './shared/pages/ViewCourse';
import ContributorCampaigns from "./contrib_dashboard/pages/Campaigns"
import GettingStarted from './shared/pages/GettingStarted';

function App() {
  return (
    <>
      <Router>
        <Routes>

          <Route path='/' element={<WebLayout />}>
            <Route index element={<AdminLoginPage />} />
            <Route path="otp-authentication" element={<ContribOtp />} />
            <Route path="contrib_login" element={<ContribLoginPage />} />
            <Route path="otp-authentication-gl" element={<GroupLeaderOtp />} />
            <Route path="groupleader_login" element={<GroupLeaderLoginPage />} />
            <Route path="groupleader_otp_login" element={<GroupLeaderOTPLogin />} />
            <Route path="otp-authentication-subs" element={<SubscriberOtp />} />
            <Route path="subscriber_login" element={<SubscriberLoginPage />} />
            <Route path="subscriber_otp_login" element={<SubscriberOTPLogin />} />
          </Route>

          <Route path='/dashboard' element={<DashLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path="groups" element={<GroupManagmentPage />} />
            <Route path="courses" element={<UploadCourse />} />
            <Route path="buy_new_license" element={<AdminBuyNewLicense />} />
            <Route path="mycart" element={<AdminMyCart />} />
            <Route path="course_details" element={<CourseDetails />} />
            <Route path="campaigns" element={<Campaigns />} />
          </Route>

          <Route path='/contrib_dashboard' element={<ContribDashLayout />}>
            <Route index element={<ContribDashPage />} />
            <Route path="groups" element={<ContribGroupManagmentPage />} />
            <Route path="buy_new_license" element={<BuyNewLicense />} />
            <Route path="mycart" element={<MyCart />} />
            <Route path="campaigns" element={<ContributorCampaigns />} />
          </Route>

          <Route path='/groupleader_dashboard' element={<GroupLeaderDashLayout />}>
            <Route index element={<GroupLeaderDashPage />} />
            <Route path="courses" element={<GLCourses />} />
            <Route path="view_course" element={<ViewCourse />} />
            <Route path="getting_started" element={<GettingStarted />} />
          </Route>

          <Route path='/subscriber_dashboard' element={<SubsDashLayout />}>
            <Route index element={<SubsDashPage />} />
            <Route path="courses" element={<SCourses />} />
            <Route path="view_course" element={<ViewCourse />} />
            <Route path="getting_started" element={<GettingStarted />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </Router >
    </>
  );
}

export default App;

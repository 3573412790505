import React, { useState, useMemo, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import { CircularProgress } from "@mui/material";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	overflow: "auto",
	maxHeight: "80vh",
};

const UserReportTable = () => {
	const { currentUser } = useAuth();
	const [usersData, setUsersData] = useState([]);
	const [apiLoader, setapiLoader] = useState(true);
	const [rowsToShow, setRowsToShow] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(5);
	const [openDetailsData, setopenDetailsData] = useState(null);

	const handleRowPerPageChange = (event) => {
		setRowPerPage(event.target.value);
	};

	const [openFirst, setOpenFirst] = useState(false);
	const handleOpenFirst = (data) => () => {
		setopenDetailsData(data);
		setOpenFirst(true);
	};
	const handleCloseFirst = () => {
		setopenDetailsData(null);
		setOpenFirst(false);
	};

	useEffect(() => {
		setRowsToShow(usersData.slice(0, rowPerPage));
	}, [usersData, rowPerPage]);

	const totalPage = useMemo(
		() => Math.ceil(usersData.length / rowPerPage),
		[usersData.length, rowPerPage],
	);

	const generatePaginationLinks = () => {
		const paginationLinks = [];
		const ellipsis = "...";

		if (totalPage <= 7) {
			for (let i = 1; i <= totalPage; i++) {
				paginationLinks.push(i);
			}
		} else {
			if (currentPage <= 4) {
				for (let i = 1; i <= 5; i++) {
					paginationLinks.push(i);
				}
				paginationLinks.push(ellipsis);
				paginationLinks.push(totalPage);
			} else if (currentPage >= totalPage - 3) {
				paginationLinks.push(1);
				paginationLinks.push(ellipsis);
				for (let i = totalPage - 4; i <= totalPage; i++) {
					paginationLinks.push(i);
				}
			} else {
				paginationLinks.push(1);
				paginationLinks.push(ellipsis);

				for (let i = currentPage - 1; i <= currentPage + 1; i++) {
					paginationLinks.push(i);
				}

				paginationLinks.push(ellipsis);
				paginationLinks.push(totalPage);
			}
		}
		return paginationLinks;
	};

	const transformUserData = (userStats) => {
		return userStats.map((user) => ({
			firstName: user.firstname,
			lastName: user.lastname,
			email: user.email,
			modulesEnrolled: user.enrolled_courses,
			notStarted: user.not_started_courses,
			inProgress: user.in_progress_courses,
			complete: user.completed_courses,
		}));
	};

	const getUsersData = async () => {
		try {
			const resp = await axiosInstance.post(`/users_report/all`, {
				currentUser,
			});
			const transformedUsersData =
				resp.data.data &&
				transformUserData(resp.data.data?.all_group_users_stats);
			setUsersData(transformedUsersData);
		} catch (error) {
			console.log(error.message);
		} finally {
			setapiLoader(false);
		}
	};

	useEffect(() => {
		getUsersData();
	}, []);

	const nextPage = () => {
		const startIndex = rowPerPage * (currentPage + 1);
		const endIndex = startIndex + rowPerPage;
		const newArray = usersData.slice(startIndex, endIndex);
		setRowsToShow(newArray);
		setCurrentPage(currentPage + 1);
	};

	const changePage = (value) => {
		const startIndex = value * rowPerPage;
		const endIndex = startIndex + rowPerPage;
		const newArray = usersData.slice(startIndex, endIndex);
		setRowsToShow(newArray);
		setCurrentPage(value);
	};

	const previousPage = () => {
		const startIndex = (currentPage - 1) * rowPerPage;
		const endIndex = startIndex + rowPerPage;
		const newArray = usersData.slice(startIndex, endIndex);
		setRowsToShow(newArray);
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		} else {
			setCurrentPage(0);
		}
	};

	return (
		<>
			{apiLoader ? (
				<>
					<div className="w-full flex flex-col justify-center items-center min-h-screen">
						<CircularProgress />
					</div>
				</>
			) : (
				<>
					<div className="w-full flex flex-col justify-center items-center">
						<div className="w-full h-16 flex flex-row justify-end items-center rounded-t-lg text-white font-semibold text-base gap-4 pt-3 pl-10 pr-10 bg-[#00486F]"></div>
					</div>

					<div className="h-full bg-white flex items-center justify-center py-4">
						<div className="w-full px-2">
							<div className="w-full overflow-x-scroll md:overflow-auto max-w-7xl 2xl:max-w-none mt-2 ">
								<table className="table-auto overflow-scroll md:overflow-auto w-full text-left font-inter border ">
									<thead className="rounded-lg text-base text-white font-semibold w-full border-t-2 border-gray-300 pt-6 pb-6">
										<tr>
											<th className="py-3 px-3 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												First Name
											</th>
											<th className="py-3 px-3 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												Last Name
											</th>
											<th className="py-3 px-3 justify-center gap-1 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												Email
											</th>
											<th className="py-3 px-3 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												Modules Enrolled
											</th>
											<th className="py-3 px-3 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												Not Started
											</th>
											<th className="py-3 px-3 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												In Progress
											</th>
											<th className="py-3 px-3 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												Complete
											</th>
											<th className="py-3 px-3 text-[#00486F] sm:text-base font-bold whitespace-nowrap">
												Completed
											</th>
										</tr>
									</thead>

									<tbody>
										{rowsToShow &&
											rowsToShow?.map((data, index) => (
												<tr
													className={`${
														index % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"
													}`}
													key={index}
												>
													<td
														className={`py-2 px-3 font-normal text-base ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														{!data?.firstName ? (
															<div> - </div>
														) : (
															<div>{data.firstName}</div>
														)}
													</td>
													<td
														className={`py-2 px-3 font-normal text-base ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														{!data?.lastName ? (
															<div> - </div>
														) : (
															<div>{data.lastName}</div>
														)}
													</td>
													<td
														className={`py-2 px-3 font-normal text-base ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														{!data?.email ? (
															<div> - </div>
														) : (
															<div>{data.email}</div>
														)}
													</td>
													<td
														className={`py-2 px-3 text-base text-center font-normal ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														{!data?.modulesEnrolled ? (
															<div> - </div>
														) : (
															<div>{data.modulesEnrolled}</div>
														)}
													</td>
													<td
														className={`py-2 px-3 text-base text-center font-normal ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														{!data?.notStarted ? (
															<div> - </div>
														) : (
															<div>{data.notStarted}</div>
														)}
													</td>
													<td
														className={`py-2 px-3 text-base text-center font-normal ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														{!data?.inProgress ? (
															<div> - </div>
														) : (
															<div>{data.inProgress}</div>
														)}
													</td>
													<td
														className={`py-2 px-3 text-base text-center font-normal ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														{!data?.complete ? (
															<div> - </div>
														) : (
															<div>{data.complete}</div>
														)}
													</td>
													<td
														className={`py-2 px-3 text-base  font-normal ${
															index == 0
																? "border-t-2 border-gray-300"
																: index == rowsToShow?.length
																	? "border-y"
																	: "border-t"
														} whitespace-nowrap`}
													>
														<button
															onClick={handleOpenFirst(data)}
															className="bg-[#00486F] rounded-3xl text-white py-1 px-4"
														>
															View Details
														</button>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>

							<div className="w-full flex justify-center sm:justify-between xl:flex-row flex-col gap-10 mt-12 lg:mt-8 px-0 lg:px-4 xl:px-4 items-center">
								<div className="text-base text-center">
									Showing
									<span className="font-bold bg-[#00486F] text-white mx-2 p-2 text-center rounded-lg">
										{currentPage === 0 ? 1 : currentPage * rowPerPage + 1}
									</span>
									to{" "}
									<span className="font-bold bg-[#00486F] text-white mx-2 py-2 px-3 text-center rounded-lg">
										{currentPage === totalPage - 1
											? usersData?.length
											: (currentPage + 1) * rowPerPage}
									</span>{" "}
									of{" "}
									<span className="font-bold bg-[#00486F] text-white mx-2 py-2 px-3 text-center rounded-lg">
										{usersData?.length}
									</span>{" "}
									entries
								</div>

								<div className="flex flex-row justify-center items-center gap-4">
									<div> Rows Per Page </div>
									<Box sx={{ width: 200 }}>
										<FormControl fullWidth>
											<Select
												id="rows-per-page"
												value={rowPerPage}
												onChange={handleRowPerPageChange}
												sx={{
													height: 40,
													backgroundColor: "#00486F",
													color: "white",
													borderRadius: "8px",
													".MuiOutlinedInput-notchedOutline": {
														borderColor: "transparent",
													},
													"&:hover .MuiOutlinedInput-notchedOutline": {
														borderColor: "transparent",
													},
													"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
														borderColor: "transparent",
													},
													".MuiSelect-icon": {
														color: "white",
													},
													"& .MuiSelect-select": {
														borderRadius: "8px",
													},
													"& .MuiListItem-root": {
														"&:hover": {
															backgroundColor: "white",
															color: "black",
														},
													},
													"& .Mui-selected": {
														backgroundColor: "white",
														color: "black",
													},
												}}
											>
												<MenuItem value={5}>5</MenuItem>
												<MenuItem value={10}>10</MenuItem>
												<MenuItem value={15}>15</MenuItem>
											</Select>
										</FormControl>
									</Box>
								</div>

								<div className="flex">
									<ul
										className="flex justify-center items-center gap-x-[10px] z-30"
										role="navigation"
										aria-label="Pagination"
									>
										<li
											className={` prev-btn flex items-center justify-center w-[36px] rounded-[6px] h-[36px] border-[1px] border-solid border-[#E4E4EB] disabled] ${
												currentPage == 0
													? "bg-[#cccccc] pointer-events-none"
													: " cursor-pointer"
											}`}
											onClick={previousPage}
										>
											<img src="https://www.tailwindtap.com/assets/travelagency-admin/leftarrow.svg" />
										</li>

										{generatePaginationLinks().map((item, index) => (
											<li
												key={index}
												onClick={() => changePage(item - 1)}
												className={`flex items-center justify-center w-[36px] rounded-[6px] h-[34px] border-solid border-[2px] cursor-pointer ${
													currentPage === item - 1
														? "text-white bg-[#00486F]"
														: "border-[#E4E4EB]"
												}`}
											>
												<span aria-hidden="true">{item}</span>
											</li>
										))}

										<li
											className={`flex items-center justify-center w-[36px] rounded-[6px] h-[36px] border-[1px] border-solid border-[#E4E4EB] ${
												currentPage == totalPage - 1
													? "bg-[#cccccc] pointer-events-none"
													: " cursor-pointer"
											}`}
											onClick={nextPage}
										>
											<img src="https://www.tailwindtap.com/assets/travelagency-admin/rightarrow.svg" />
										</li>
									</ul>
								</div>
							</div>

							{openDetailsData && (
								<Modal
									open={openFirst}
									onClose={handleCloseFirst}
									aria-describedby="modal-data"
								>
									<Box sx={style} noValidate>
										<div
											id="modal-data"
											className="w-full h-full flex flex-col justify-start items-center gap-3"
										>
											<div className="w-full h-full flex flex-col justify-center items-center gap-5">
												<h2 className="text-xl font-bold">Details</h2>
											</div>
											<div className="w-full h-full flex flex-col justify-center items-center gap-5">
												<div className="w-80 flex flex-col justify-start items-start gap-2">
													<label className="text-sm font-semibold">
														{" "}
														Full Name{" "}
													</label>
													{/* value={!dataWithLeadId.id ? '---' : dataWithLeadId.id}  */}
													<TextField
														sx={{ width: "100%" }}
														id="recordID"
														value={
															openDetailsData.firstName +
															" " +
															openDetailsData.lastName
														}
													/>
												</div>
												<div className="w-80 flex flex-col justify-start items-start gap-2">
													<label className="text-sm font-semibold">
														{" "}
														Email
													</label>
													<TextField
														sx={{ width: "100%" }}
														id="fullNameID"
														value={openDetailsData.email}
													/>
												</div>
											</div>
										</div>
									</Box>
								</Modal>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default UserReportTable;

import React, { useState, useEffect, useMemo } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import axiosInstance from "../../utils/axiosConfig"
import { MaterialReactTable } from 'material-react-table';
import Box from '@mui/material/Box';
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom"

const UploadCourse = () => {
    const [createCourseModalOpen, setCreateCourseModalOpen] = useState(false);
    const [coursesData, setCoursesData] = useState([]);

    const handleCreateCourseModal = () => {
        setCreateCourseModalOpen(!createCourseModalOpen)
    }

    const [createCourseForm, setCreateCourseForm] = useState({
        title: '',
        description: '',
        priceperseat: ''
    });

    const getAllCourses = async () => {
        try {
            const result = await axiosInstance.get("/courses/all");
            const courses = result.data.data
            setCoursesData(courses)
        } catch (error) {
            console.log(error)
        }
    }

    const handleCreateCourse = async () => {
        try {
            await axiosInstance.post("/courses/add", {
                title: createCourseForm.title,
                description: createCourseForm.description,
                priceperseat: createCourseForm.priceperseat
            })
            toast.success("Course created successfully.")
            handleCreateCourseModal()
            setCreateCourseForm({
                title: '',
                description: '',
                priceperseat: ''
            })
            getAllCourses()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllCourses()
    }, [])


    const CourseColumns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'title',
                header: 'Title',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'description',
                header: 'Description',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'priceperseat',
                header: '$ / Seat',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {"$" + cell.getValue()}
                    </Box>
                )
            },
            {
                header: "Details",
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        <Link target='_blank' to={`/dashboard/course_details?id=${cell.row.original.id}`}>
                            <Button variant='contained'>
                                <FaLocationArrow />
                            </Button>
                        </Link>
                    </Box >
                )
            }
        ],
        [],
    );

    return (
        <>
            <div className="w-full min-h-screen flex flex-col bg-[#FAFAFA] justify-start items-center">
                <ToastContainer />

                <div className="w-[90%] flex flex-row gap-5 justify-between items-center">
                    <h1 className="text-[#00486F] font-bold text-[30px] mt-5 mb-5">All Courses</h1>
                    <Button variant="outlined" onClick={handleCreateCourseModal}>
                        Add Course
                    </Button>
                </div>


                <div className='flex flex-col justify-center items-start gap-1 w-[90%]'>
                    <h1 className="text-black font-bold text-[25px] mt-5 mb-5">All Courses</h1>
                    <p className='font-semibold'>{coursesData?.length} Course(s)</p>
                    <div className='w-full'>
                        {coursesData && (<MaterialReactTable
                            columns={CourseColumns}
                            data={coursesData}
                            initialState={{ density: 'compact' }}
                        />)}
                    </div>
                </div>


                <Dialog
                    open={createCourseModalOpen}
                    onClose={handleCreateCourseModal}
                    fullWidth
                >
                    <DialogTitle
                        sx={{ marginBottom: "20px", backgroundColor: "#00486f", color: "white", fontWeight: "bold" }}>Add Group</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="title"
                            name="title"
                            label="Course Title"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={createCourseForm.title}
                            onChange={(e) => setCreateCourseForm({ ...createCourseForm, title: e.target.value })}
                        />
                    </DialogContent>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="description"
                            name="description"
                            label="Course Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={createCourseForm.description}
                            onChange={(e) => setCreateCourseForm({ ...createCourseForm, description: e.target.value })}
                        />
                    </DialogContent>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="priceperseat"
                            name="priceperseat"
                            label="$ / Seat"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={createCourseForm.priceperseat}
                            onChange={(e) => setCreateCourseForm({ ...createCourseForm, priceperseat: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCreateCourseModal}>Cancel</Button>
                        <Button onClick={handleCreateCourse}>Add</Button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    )
}

export default UploadCourse
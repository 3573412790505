import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosConfig';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    FormControlLabel,
    InputLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { h5pVideosData } from './data/h5pVideosData';
import Spinner from '../../shared/components/Spinner';

const CourseDetails = () => {
    const [loading_course, setloading_course] = useState(true);
    const [editImage, setEditImage] = useState(false);
    const [courseDetails, setCourseDetails] = useState({
        id: '',
        title: '',
        description: '',
        priceperseat: '',
        featuredImage: null,
        lessons: []
    });

    const [newLesson, setNewLesson] = useState({
        title: '',
        description: '',
        lesson_video_path: '',
        lesson_video_name: '',
    });

    const [imagePreview, setImagePreview] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const getCourseDetails = async (id) => {
        try {
            const response = await axiosInstance.get(`/courses/all_course_details/${id}`);
            const data = response.data.data;
            setCourseDetails({
                id: data.id,
                title: data.title,
                featuredImage: data.featuredImage,
                description: data.description,
                priceperseat: data.priceperseat,
                lessons: data.lessons ? data.lessons.map(lesson => ({
                    ...lesson,
                    lesson_video_path: lesson.materials ? lesson.materials[0].doc_link : "",
                    lesson_video_name: lesson.materials ? lesson.materials[0].doc_name : "",
                    isEditing: false
                })) : []
            });
            setloading_course(false)
        } catch (error) {
            console.log("Error getting course details:", error);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('id');
        if (id) {
            getCourseDetails(id);
        }
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setNewImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const handleLessonChange = (e, index, videoName) => {
        const { name, value } = e.target;
        const updatedLessons = [...courseDetails.lessons];
        if (name === 'lesson_video_path') {
            updatedLessons[index] = {
                ...updatedLessons[index],
                [name]: value,
                lesson_video_name: videoName || updatedLessons[index].lesson_video_name
            };
        } else {
            updatedLessons[index] = { ...updatedLessons[index], [name]: value };
        }
        setCourseDetails({ ...courseDetails, lessons: updatedLessons });
    };

    const handleNewLessonChange = (e) => {
        const { name, value } = e.target;
        setNewLesson({ ...newLesson, [name]: value });
    };

    const addLesson = async () => {
        if (newLesson.title && newLesson.description) {
            const formData = new FormData();
            formData.append('title', newLesson.title);
            formData.append('description', newLesson.description);
            formData.append('lesson_video_path', newLesson.lesson_video_path);
            formData.append('lesson_video_name', newLesson.lesson_video_name);
            try {
                const response = await axiosInstance.post(`/courses/add_lesson/${courseDetails.id}`, formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data.success) {
                    toast.success("Lesson Added!");
                    getCourseDetails(courseDetails.id)
                    setNewLesson({ title: '', description: '', lesson_video_path: '', lesson_video_name: '' });
                    setIsDialogOpen(false);
                }
            } catch (error) {
                console.log("Error adding lesson:", error);
            }
        } else {
            alert("Title and description are required.");
        }
    };

    const updateLesson = async (index) => {
        const lessonToUpdate = courseDetails.lessons[index];
        try {
            const response = await axiosInstance.put(`/courses/update_lesson/${courseDetails.id}/${lessonToUpdate.id}`, {
                title: lessonToUpdate.title,
                description: lessonToUpdate.description,
                lesson_video_path: lessonToUpdate.lesson_video_path,
                lesson_video_name: lessonToUpdate.lesson_video_name
            });
            if (response.data.success) {
                const updatedLessons = [...courseDetails.lessons];
                updatedLessons[index] = { ...updatedLessons[index], isEditing: false };
                setCourseDetails({ ...courseDetails, lessons: updatedLessons });
                toast.success("Lesson Updated!");
            }
        } catch (error) {
            console.log("Error updating lesson:", error);
        }
    };

    const deleteLesson = async (index) => {
        const lessonToDelete = courseDetails.lessons[index];
        try {
            const response = await axiosInstance.delete(`/courses/delete_lesson/${courseDetails.id}/${lessonToDelete.id}`);
            if (response.data.success) {
                const updatedLessons = [...courseDetails.lessons];
                updatedLessons.splice(index, 1);
                setCourseDetails({ ...courseDetails, lessons: updatedLessons });
                toast.success("Lesson Deleted!");
            }
        } catch (error) {
            console.log("Error deleting lesson:", error);
        }
    };

    const handleImageSubmit = async (e) => {
        const formData = new FormData();
        if (newImage) {
            formData.append('featuredImage', newImage);
        }
        try {
            const response = await axiosInstance.put(`/courses/set_image/${courseDetails.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.success) {
                toast.success("Featured Image Updated!");
                getCourseDetails(courseDetails.id);
                setNewImage(null);
                setImagePreview(null);
                setEditImage(false)
            }
        } catch (error) {
            console.log("Error updating featured image:", error);
        }
    };

    const toggleEditing = (index) => {
        const updatedLessons = [...courseDetails.lessons];
        updatedLessons[index].isEditing = !updatedLessons[index].isEditing;
        setCourseDetails({ ...courseDetails, lessons: updatedLessons });
    };

    return (
        <div className='w-full flex flex-col min-h-screen justify-start items-center'>
            <ToastContainer />
            {loading_course === false ? (
                <div className='w-[95%] bg-white p-6 rounded-lg shadow-md'>
                    <h1 className='font-semibold text-2xl mb-4'>Course details for: <span className='font-normal'>{courseDetails.title}</span></h1>
                    <p className='mb-2'><span className='font-semibold'>Description: </span>{courseDetails.description}</p>
                    <p className='mb-2'><span className='font-semibold'>Price: </span>$ {courseDetails.priceperseat} / Seat</p>

                    {courseDetails.featuredImage && !imagePreview && (
                        <div className='w-full flex flex-col justify-center items-center'>
                            <img src={`${process.env.REACT_APP_BASE_URL}/${courseDetails.featuredImage || null}`} alt={`${courseDetails.featuredImage}`} />
                        </div>
                    )}

                    <div className="mb-4">
                        <label className="block font-semibold mb-1">Edit Image</label>
                        <Switch
                            checked={editImage}
                            onChange={(e) => setEditImage(e.target.checked)}
                        />
                    </div>

                    {editImage && (
                        <div className='w-full'>
                            <label className='block font-semibold mb-1'>Featured Image</label>
                            <input
                                type='file'
                                name='featuredImage'
                                onChange={handleFileChange}
                                className='mb-4 p-2 border rounded w-full'
                            />

                            {imagePreview && (
                                <div style={{ position: 'relative' }}>
                                    <img
                                        src={imagePreview}
                                        alt="Image Preview"
                                        className='mb-4'
                                        style={{ width: '100%', height: '200px' }}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '8px',
                                            right: '8px',
                                            cursor: 'pointer',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            padding: '4px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => {
                                            setImagePreview(null);
                                            setNewImage(null);
                                        }}
                                    >
                                        <CloseIcon style={{ color: '#fff' }} />
                                    </div>
                                </div>
                            )}

                            <Button onClick={handleImageSubmit} variant="contained" color="primary">
                                Update Featured Image
                            </Button>
                        </div>
                    )}

                    <h2 className='font-semibold text-xl mb-2'>Lessons</h2>
                    {courseDetails.lessons.map((lesson, index) => (
                        <Accordion slotProps={{ transition: { unmountOnExit: true } }} key={index} sx={{ width: "100%", marginBottom: "10px" }}>
                            <AccordionSummary sx={{
                                backgroundColor: "#02496f",
                                color: "#FFFFFF",
                                borderTopLeftRadius: '8px',
                                borderTopRightRadius: '8px'
                            }} expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}>
                                <Typography>{lesson.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={lesson.isEditing}
                                            onChange={() => toggleEditing(index)}
                                            color="primary"
                                        />
                                    }
                                    label="Enable Editing"
                                />
                                <TextField
                                    label="Title"
                                    name="title"
                                    value={lesson.title}
                                    onChange={(e) => handleLessonChange(e, index)}
                                    sx={{ marginTop: "10px", marginBottom: "10px" }}
                                    fullWidth
                                    disabled={!lesson.isEditing}
                                />
                                <TextField
                                    label="Description"
                                    name="description"
                                    value={lesson.description}
                                    onChange={(e) => handleLessonChange(e, index)}
                                    sx={{ marginBottom: "10px" }}
                                    fullWidth
                                    multiline
                                    disabled={!lesson.isEditing}
                                />
                                {lesson.materials[0] !== '' && (
                                    <FormControl fullWidth>
                                        <InputLabel id="lesson_video_path">Update video for lesson</InputLabel>
                                        <Select
                                            labelId="lesson_video_path"
                                            name='lesson_video_path'
                                            value={lesson.lesson_video_path}
                                            onChange={(e, { props }) => handleLessonChange(e, index, props.video_name)}
                                            disabled={!lesson.isEditing}
                                        >
                                            {h5pVideosData && h5pVideosData.map((video) => (
                                                <MenuItem video_name={video.name} key={video.path} value={video.path}>
                                                    {video.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                )}
                                <div className='flex justify-end mt-3'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => updateLesson(index)}
                                        disabled={!lesson.isEditing}
                                        sx={{ marginRight: "5px" }}
                                    >
                                        Update Lesson
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => deleteLesson(index)}
                                    >
                                        Delete Lesson
                                    </Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    <Button sx={{ marginTop: "10px" }} variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                        Add Lesson
                    </Button>

                    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                        <DialogTitle>Add New Lesson</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Title"
                                name="title"
                                value={newLesson.title}
                                onChange={handleNewLessonChange}
                                sx={{ marginTop: "10px", marginBottom: "10px" }}
                                fullWidth
                            />
                            <TextField
                                label="Description"
                                name="description"
                                value={newLesson.description}
                                onChange={handleNewLessonChange}
                                sx={{ marginBottom: "10px" }}
                                fullWidth
                                multiline
                            />
                            <FormControl fullWidth>
                                <InputLabel id="lesson_video_path">Select a video for lesson</InputLabel>
                                <Select
                                    labelId="lesson_video_path"
                                    name='lesson_video_path'
                                    value={newLesson.lesson_video_path}
                                    onChange={(e, { props }) => setNewLesson({ ...newLesson, lesson_video_path: e.target.value, lesson_video_name: props.video_name })}
                                >
                                    {h5pVideosData && h5pVideosData.map((video) => (
                                        <MenuItem video_name={video.name} key={video.path} value={video.path}>
                                            {video.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsDialogOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={addLesson} color="primary">
                                Add Lesson
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            ) : (
                <>
                    <div className='w-[95%] bg-white flex flex-col justify-center items-center p-6 rounded-lg shadow-md'>
                        <Spinner />
                    </div>
                </>
            )}
        </div>
    );
};

export default CourseDetails;
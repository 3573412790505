import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from '../../../utils/axiosConfig';
import { Box } from '@mui/material';
import { PencilIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const Contributor = () => {

    const [ContributorsData, setContributorsData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({ firstname: '', lastname: '', email: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [delbtnloader, setDelbtnloader] = useState([]);

    const Contribcolumns = useMemo(
        () => [
            {
                accessorKey: 'firstname',
                header: 'First Name',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'lastname',
                header: 'Last Name',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                header: 'Actions',
                size: 100,
                Cell: ({ row }) => (
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                        <button onClick={() => handleEditContributor(row.original)} className='flex outline-none flex-row justify-center items-center px-3 py-2 rounded-md gap-1 bg-slate-600 text-white font-semibold'>
                            <span>Edit</span>
                            <PencilIcon className='w-[15px] h-[15px]' />
                        </button>
                        <button
                            disabled={delbtnloader[row.original.id]}
                            onClick={() => handleDeleteContributor(row.original.id)}
                            className='flex outline-none flex-row justify-center items-center px-3 py-2 rounded-md gap-1 bg-red-800 text-white font-semibold'
                        >
                            {delbtnloader[row.original.id] ? (
                                <>
                                    <span>Deleting</span>
                                    <ArrowPathIcon className='animate-spin w-[15px] h-[15px]' />
                                </>
                            ) : (
                                <>
                                    <span>Delete</span>
                                    <TrashIcon className='w-[15px] h-[15px]' />
                                </>
                            )}
                        </button>
                    </Box>
                )
            },
        ],
        [delbtnloader],
    );

    const getAllContributors = async () => {
        try {
            const response = await axiosInstance.get("/contributors/all");
            setContributorsData(response.data.contributors);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditContributor = (contributor) => {
        setFormData(contributor);
        setCurrentId(contributor.id);
        setIsEditMode(true);
        setOpenModal(true);
    };

    const handleDeleteContributor = async (id) => {
        try {
            setDelbtnloader(prev => ({ ...prev, [id]: true }));
            const response = await axiosInstance.delete("/contributors/del", { data: { id: id } });
            toast.success("Contributor deleted with success!");
            getAllContributors();
        } catch (error) {
            console.log(error);
            toast.error("Error deleting contributor!");
        } finally {
            setDelbtnloader(prev => ({ ...prev, [id]: false }));
        }
    };

    const handleAddContributor = () => {
        setFormData({ firstname: '', lastname: '', email: '' });
        setIsEditMode(false);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setFormData({ firstname: '', lastname: '', email: '' });
        setCurrentId(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isEditMode) {
            try {
                const response = await axiosInstance.put(`/contributors/update`, {
                    id: currentId,
                    ...formData,
                });
                toast.success("Contributor updated successfully!");
            } catch (error) {
                console.log(error);
                toast.error("Error updating contributor!");
            }
        } else {
            try {
                const response = await axiosInstance.post(`/contributors/add`, formData);
                toast.success("Contributor added successfully!");
            } catch (error) {
                console.log(error);
                toast.error("Error adding contributor!");
            }
        }
        handleCloseModal();
        getAllContributors();
    };

    useEffect(() => {
        getAllContributors();
    }, []);

    return (
        <>
            <div className="w-[90%] flex flex-col gap-5 justify-center items-start">
                <Button variant="outlined" onClick={handleAddContributor}>
                    Add Contributor
                </Button>
                <h1 className="text-black font-bold text-[25px] mt-5 mb-5">All Contributors</h1>
                {ContributorsData && (<p className='font-semibold'>{ContributorsData?.length} Contributor(s)</p>)}
                <div className='w-full'>
                    <MaterialReactTable
                        columns={Contribcolumns}
                        data={ContributorsData}
                    />
                </div>
            </div>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                }}
                fullWidth
            >
                <DialogTitle
                    sx={{ marginBottom: "20px", backgroundColor: "#00486f", color: "white", fontWeight: "bold" }}>{isEditMode ? 'Edit Contributor' : 'Add Contributor'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.firstname}
                        onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                    />
                </DialogContent>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="lastname"
                        name="lastname"
                        label="Last Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.lastname}
                        onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                    />
                </DialogContent>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button type="submit">{isEditMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Contributor
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LOGO from "../../assets/logo.png"
import Logout from "./Logout";

const SideNav = () => {

  const menus = [
    { name: "Subscriber Dashboard", link: "/subscriber_dashboard" },
    { name: "Courses", link: "/subscriber_dashboard/courses" },
  ];

  const [open, setOpen] = useState(true);

  const location = useLocation();

  return (
    <section className="flex">
      <div
        className={` bg-white min-h-screen shadow-md w-[260px] duration-500 text-black px-2 lg:px-4 py-1 sm:py-2 md:py-2 lg:py-4 xl:py-6 2xl:py-6`}
      >
        <div className="py-3 flex bg-white justify-center items-center">
          <img
            className="cursor-pointer"
            src={LOGO}
            alt=""
          />
        </div>

        <div className="mt-4 flex flex-col gap-4 relative">
          {menus?.map((menu, i) => (
            <Link
              to={menu?.link}
              key={i}
              className={`group flex items-center text-base gap-2 font-poppins py-2 pl-2 pr-4 ${location.pathname === menu?.link ? "bg-[rgba(112,127,221,0.1) text-[#ffffff] bg-[#00486F] rounded-md" : "text-black"} hover:bg-[rgba(112,127,221,0.1)] hover:text-black rounded-md`}
            >
              <h2
                style={{
                  transitionDelay: `${i + 2}00ms`,
                  fontWeight: "bold",
                }}
                className={`whitespace-pre duration-200`}
              >
                {menu?.name}
              </h2>
            </Link>
          ))}
          <Logout isOpen={open} />
        </div>
      </div>
    </section>
  );
};

export default SideNav;

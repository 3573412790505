import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setloading] = useState(true);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    if (storedToken && storedUser) {
      let user = JSON.parse(storedUser)
      setCurrentUser(user);
      setUserRole(user.role);
    }
    setloading(false);
  }, []);


  const login = (user) => {
    localStorage.setItem('token', user.token);
    localStorage.setItem('user', JSON.stringify(user.user));
    setCurrentUser(user.user);
    setUserRole(user.user.role);
    setloading(false)
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setCurrentUser(null);
    setUserRole(null);
  };

  return (
    <AuthContext.Provider value={{ loading, currentUser, userRole, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

import React from 'react'

const DashboardPage = () => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col bg-[#FAFAFA] justify-start items-center">
        <h1 className="text-[#00486F] font-bold text-[30px] mt-5 mb-5">Subscriber Dashboard</h1>
      </div>
    </>
  )
}

export default DashboardPage
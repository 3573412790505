import React from 'react'
import { Link } from "react-router-dom"

const Header = () => {
    return (
        <>
            <nav className="font-sans fixed top-0 flex flex-col text-center sm:flex-row sm:text-left sm:justify-between py-4 px-6 bg-white shadow sm:items-baseline w-full">
                <div className="mb-2 sm:mb-0">
                    <Link to="/"><p className="text-lg no-underline text-grey-darkest hover:text-blue-dark">Admin</p></Link>
                </div>
                <div className='flex flex-row justify-center items-center gap-1'>
                    <Link to="/groupleader_otp_login"><p className="text-md border-[3px] rounded-[10px] px-2 py-1 border-black no-underline text-grey-darkest hover:text-blue-dark ml-2">Group Leader pwdless signin</p></Link>
                    <Link to="/subscriber_otp_login"><p className="text-md border-[3px] rounded-[10px] px-2 py-1 border-black no-underline text-grey-darkest hover:text-blue-dark ml-2">Subscriber pwdless signin</p></Link>
                </div>
                <div className='flex flex-row justify-center items-center gap-1'>
                    <Link to="/contrib_login"><p className="text-md border-[3px] rounded-[10px] px-2 py-1 border-black no-underline text-grey-darkest hover:text-blue-dark ml-2">Contributor</p></Link>
                    <Link to="/groupleader_login"><p className="text-md border-[3px] rounded-[10px] px-2 py-1 border-black no-underline text-grey-darkest hover:text-blue-dark ml-2">Group Leader</p></Link>
                    <Link to="/subscriber_login"><p className="text-md border-[3px] rounded-[10px] px-2 py-1 border-black no-underline text-grey-darkest hover:text-blue-dark ml-2">Subscriber</p></Link>
                </div>
            </nav>
        </>
    )
}

export default Header
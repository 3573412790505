import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-white text-gray-800">
            <div className="text-5xl font-black">404</div>
            <div className="w-16 h-1 my-3 bg-primary"></div>
            <p className="text-2xl font-light">Not Found</p>
            <Link to="/">
                <button className="px-4 py-2 mt-4 text-lg border rounded-lg hover:bg-gray-100">
                    Go Back To LMS
                </button>
            </Link>
        </div>
    );
};

export default NotFoundPage;

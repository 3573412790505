import React, { useState, useEffect } from 'react';
import courseimg from "../../assets/course.png"
import axiosInstance from '../../utils/axiosConfig';
import LinearProgress from '@mui/material/LinearProgress';
import Spinner from '../components/Spinner';
import { useAuth } from "../../AuthContext"
import H5Player from "../components/h5p/H5Player"

const ViewCourse = () => {

    const { currentUser } = useAuth()
    const [CourseData, setCourseData] = useState(null);
    const [CourseDataLoader, setCourseDataLoader] = useState(true);

    const getCourseDetails = async (course_id) => {
        try {
            const response = await axiosInstance.get(`/courses/all_course_details/${course_id}`)
            setCourseData(response.data.data)
            setCourseDataLoader(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const course_id = params.get("course_id")
        if (course_id) {
            getCourseDetails(course_id);
        }
    }, [])

    return (
        <>
            {CourseDataLoader === false ? (
                <div className='w-full flex flex-col min-h-screen justify-start items-start'>
                    <img
                        className="w-full mb-4 shadow-lg rounded-lg border h-[300px]"
                        src={CourseData.featuredImage ? `${process.env.REACT_APP_BASE_URL}/${CourseData.featuredImage}` : courseimg}
                        alt=""
                    />
                    <div className='w-full flex flex-col justify-start items-start'>

                        <p className="text-[21px] font-bold text-black leading-loose">Course Title: <span className="text-[20px] text-gray-700">{CourseData.title}</span></p>

                        <p className="text-[21px] font-bold text-black leading-loose">Course Description: <span className="text-[20px] text-gray-700">{CourseData.description}</span></p>

                        <p className="text-[21px] font-bold text-black leading-loose">Course Price Per Seat: <span className="text-[20px] text-gray-700">${CourseData.priceperseat}</span></p>


                    </div>
                    <div className='w-full mt-4 flex flex-col justify-center items-center'>
                        {CourseData.lessons.length > 0 && currentUser ? (
                            <CourseBrowser lessons={CourseData.lessons} course_id={CourseData.id} user={currentUser} />
                        ) : (
                            <>
                                <p className="text-2xl font-bold text-black leading-loose">No Lessons Uploaded.</p>
                            </>
                        )
                        }
                    </div>
                </div>
            ) : (
                <>
                    <div className='w-full flex flex-col min-h-screen justify-center items-center'>
                        <Spinner />
                    </div>
                </>
            )}
        </>
    );
};

const CS = () => {
    return (
        <>
            <svg
                className="w-6 text-white"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
                <polyline
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="6,12 10,16 18,8"
                />
            </svg>
        </>
    )
}

const NS = () => {
    return (
        <>
            <svg
                className="w-4 text-gray-600"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
            >
                <line
                    fill="none"
                    strokeMiterlimit="10"
                    x1="12"
                    y1="2"
                    x2="12"
                    y2="22"
                />
                <polyline
                    fill="none"
                    strokeMiterlimit="10"
                    points="19,15 12,22 5,15"
                />
            </svg>
        </>
    )
}

const CourseBrowser = ({ lessons, course_id, user }) => {
    const [currentLesson, setCurrentLesson] = useState(0);
    const [lessonCompleted, setLessonCompleted] = useState(Array(lessons.length).fill(false));
    const [lessonProgress, setLessonProgress] = useState([]);

    const getUserLessonProgress = async () => {
        try {
            const response = await axiosInstance.post(`/lesson_progress/progress/${course_id}`, {
                user_id: user.id,
                user_role: user.role,
                user_email: user.email,
            });
            setLessonProgress(response.data.progress);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (user) {
            getUserLessonProgress();
        }
    }, [user]);

    useEffect(() => {
        if (lessonProgress.length > 0) {
            const newLessonCompleted = lessons.map(lesson => {
                const progress = lessonProgress.find(progress => progress.lesson_id.toString() === lesson.id.toString());
                return progress ? true : false;
            });
            setLessonCompleted(newLessonCompleted);
        }
    }, [lessonProgress, lessons]);

    const handleNextLesson = () => {
        if (currentLesson < lessons.length - 1) {
            if (lessons[currentLesson].materials.length === 0 || (lessons[currentLesson].materials.length === 1 && lessons[currentLesson].materials[0] === '')) {
                markLessonCompleted(lessons[currentLesson].id);
            }
            setCurrentLesson(currentLesson + 1);
        } else if (currentLesson === lessons.length - 1) {
            if (lessons[currentLesson].materials.length === 0 || (lessons[currentLesson].materials.length === 1 && lessons[currentLesson].materials[0] === '')) {
                markLessonCompleted(lessons[currentLesson].id);
            }
        }
    };

    const handlePreviousLesson = () => {
        if (currentLesson > 0) {
            setCurrentLesson(currentLesson - 1);
        }
    };

    const markLessonCompleted = async (lesson_id) => {
        try {
            await axiosInstance.post("/lesson_progress/complete", {
                lesson_id,
                course_id,
                user,
            });

            const newLessonCompleted = [...lessonCompleted];
            newLessonCompleted[currentLesson] = true;
            setLessonCompleted(newLessonCompleted);

            getUserLessonProgress();
        } catch (error) {
            console.log(error);
        }
    };

    const updateCourseProgress = async () => {
        try {
            const completedLessons = lessonCompleted.filter(Boolean).length;
            const progress = (completedLessons / lessons.length) * 100;
            const isCompleted = completedLessons === lessons.length;

            await axiosInstance.post(`/course_details/progress/update`, {
                course_id,
                c_progress: progress,
                user_id: user.id,
                user_email: user.email,
                user_role: user.role,
            });

            if (isCompleted) {
                await axiosInstance.post(`/course_details/status/complete`, {
                    course_id,
                    user_id: user.id,
                    user_email: user.email,
                    user_role: user.role,
                })
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (lessonCompleted.some(Boolean)) {
            updateCourseProgress();
        }
    }, [lessonCompleted]);

    return (
        <>
            <div className='w-full flex flex-col mb-4 justify-center items-center'>
                <div className='w-full flex flex-col justify-center items-end'>
                    <p className='text-[20px] font-semibold'>
                        Course completion rate:{' '}
                        <span className='text-[#0051a3] font-extrabold text-[30px]'>
                            {(lessonCompleted.filter(Boolean).length / lessons.length * 100).toFixed(0)}%
                        </span>
                    </p>
                </div>
                <div className='w-full flex flex-col justify-center items-end'>
                    <LinearProgress
                        variant='determinate'
                        value={(lessonCompleted.filter(Boolean).length / lessons.length) * 100}
                        sx={{
                            width: '100%',
                            height: 20,
                            '& .MuiLinearProgress-barColorPrimary': {
                                backgroundColor: '#1a90ff',
                            },
                            '& .MuiLinearProgress-bar1Determinate': {
                                backgroundColor: '#0051a3',
                            },
                        }}
                    />
                </div>
            </div>

            {/* Lesson list and details */}
            <div className='flex lg:flex-row flex-col w-full justify-center items-start gap-2'>
                {/* Lesson list */}
                <div className='lg:w-[30%] w-full border shadow-lg rounded-lg p-2'>
                    {lessons.map((lesson, index) => (
                        <div className={`flex ${index > currentLesson ? 'opacity-50' : ''}`} key={index}>
                            <div className='flex flex-col items-center mr-4'>
                                <div>
                                    <div
                                        className={`flex ${lessonCompleted[index] ? 'bg-green-400' : ''
                                            } items-center justify-center w-10 h-10 border rounded-full`}
                                    >
                                        {lessonCompleted[index] ? <CS /> : <NS />}
                                    </div>
                                </div>
                                <div className='w-px h-full bg-gray-300' />
                            </div>
                            <div
                                className={`pt-1 pb-8 ${index > currentLesson ? 'cursor-not-allowed' : ''
                                    }`}
                            >
                                <p className='mb-2 text-lg font-bold'>{'Lesson ' + (index + 1)}</p>
                                <p className='text-gray-700'>{lesson.description.slice(0, 50)}...</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Lesson details */}
                <div className='lg:w-[70%] w-full'>
                    <div className='flex flex-col w-full justify-start items-start p-8 bg-white rounded shadow-lg'>
                        <div className='mb-4 w-full'>
                            <h2 className='text-2xl font-bold'>{lessons[currentLesson].title}</h2>
                            <p className='text-gray-700'>{lessons[currentLesson].description}</p>
                            <div className='w-full mt-3 mb-1 flex gap-1 p-2 flex-col justify-start items-start border shadow-lg rounded-lg'>
                                {lessons[currentLesson].materials.length > 0 &&
                                    lessons[currentLesson].materials[0].doc_link ? (
                                    lessons[currentLesson].materials.map((item, index) => (
                                        item.doc_link && (
                                            <H5Player markLessonCompleted={markLessonCompleted} lesson_id={lessons[currentLesson].id} key={`${currentLesson}-${index}`} h5pJsonPath={item.doc_link} />
                                        )
                                    ))
                                ) : (
                                    <p>No material attached to this lesson</p>
                                )
                                }

                            </div>
                        </div>
                        {lessons[currentLesson].materials.length === 0 || (lessons[currentLesson].materials.length === 1 && lessons[currentLesson].materials[0] === '') ? (
                            <div className='flex flex-row justify-between items-center w-full space-x-4'>
                                {currentLesson > 0 && (
                                    <button
                                        onClick={handlePreviousLesson}
                                        className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600'
                                    >
                                        Previous Lesson
                                    </button>
                                )}
                                <button
                                    onClick={handleNextLesson}
                                    className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600'
                                >
                                    {currentLesson < lessons.length - 1 ? 'Next Lesson' : 'Finish'}
                                </button>
                            </div>
                        ) : (
                            <div className='flex flex-col'>
                                <div className='flex flex-row justify-between items-center w-full space-x-4'>
                                    {currentLesson > 0 && (
                                        <button
                                            onClick={handlePreviousLesson}
                                            className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600'
                                        >
                                            Previous Lesson
                                        </button>
                                    )}
                                    {currentLesson < lessons.length - 1 &&
                                        lessonCompleted[currentLesson] && (
                                            <button
                                                onClick={handleNextLesson}
                                                className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600'
                                            >
                                                Next Lesson
                                            </button>
                                        )}
                                    {currentLesson === lessons.length - 1 &&
                                        lessonCompleted[currentLesson] && (
                                            <button className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600'>
                                                Course completed
                                            </button>
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewCourse;
import React, { useState, useEffect } from 'react'
import axiosInstance from "../../utils/axiosConfig"
import { useAuth } from "../../AuthContext"
import courseicon from "../../assets/course.png"
import { Link } from 'react-router-dom'

const Courses = () => {

    const { currentUser } = useAuth()
    const [Courses, setCourses] = useState([]);

    const getAllCourses = async () => {
        try {
            const response = await axiosInstance.post("/my_courses", {
                "id": currentUser.id
            })
            setCourses(response.data.courseDetails)
        } catch (error) {
            console.log("Error fetching group courses: ", error.message);
        }
    }

    useEffect(() => {
        if (currentUser) {
            getAllCourses()
        }
    }, [currentUser])
    return (
        <>
            <div className="w-full min-h-screen flex flex-col bg-[#FAFAFA] justify-start items-center">
                <h1 className="text-[#00486F] font-bold text-[30px] mt-5 mb-5">Your Group Courses</h1>

                <div className='w-[90%] flex flex-col justify-center items-start'>
                    <h1 className="text-[#00486F] font-bold text-[20px] mt-5 mb-5">
                        All Courses
                    </h1>
                    <div className='w-full grid grid-cols-4 justify-center items-center flex-wrap'>
                        {Courses && Courses.map((course, index) => (
                            <Link key={index} to={`/subscriber_dashboard/getting_started?course_id=${course.id}`}>
                                <CourseCard key={index} data={course} />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export const CourseCard = ({ data }) => {
    return (
        <>
            <div className="w-full bg-white border border-gray-200 rounded-lg shadow">
                <img
                    className="rounded-t-lg max-h-[236px] min-h-[235px] cursor-not-allowed w-full"
                    src={data.featuredImage ? `${process.env.REACT_APP_BASE_URL}/${data.featuredImage}` : courseicon}
                    alt=""
                />
                <div className="p-5">
                    <h5 className="mb-2 text-[20px] truncate font-bold tracking-tight text-gray-900">{data.title}</h5>
                    <p className="mb-3 font-normal text-gray-900 truncate">{data.description}</p>
                </div>
            </div>
        </>
    )
}

export default Courses
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MaterialReactTable } from 'material-react-table';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axiosInstance from '../../../utils/axiosConfig';
import { Box } from '@mui/material';
import { PencilIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { GoPhishAccountAPIKey, GoPhishPublicURL } from '../../../utils/constants';
import { useAuth } from '../../../AuthContext';

const Subscriber = ({ g_id }) => {

    const [SubscribersData, setSubscribersData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({ firstname: '', lastname: '', email: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [delbtnloader, setDelbtnloader] = useState([]);
    const [EmptyGroupSelError, setEmptyGroupSelError] = useState(false);
    const { currentUser } = useAuth()

    const SubscriberColumns = useMemo(
        () => [
            {
                accessorKey: 'firstname',
                header: 'First Name',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'lastname',
                header: 'Last Name',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 100,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue().length > 100 ? cell.getValue().slice(0, 100) + '...' : cell.getValue()}
                    </Box>
                )
            },
            {
                header: 'Actions',
                size: 100,
                Cell: ({ row }) => (
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                        <button onClick={() => handleEditSubscriber(row.original)} className='flex outline-none flex-row justify-center items-center px-3 py-2 rounded-md gap-1 bg-slate-600 text-white font-semibold'>
                            <span>Edit</span>
                            <PencilIcon className='w-[15px] h-[15px]' />
                        </button>
                        <button
                            disabled={delbtnloader[row.original.id]}
                            onClick={() => handleDeleteSubscriber(row.original.id, row.original.email)}
                            className='flex outline-none flex-row justify-center items-center px-3 py-2 rounded-md gap-1 bg-red-800 text-white font-semibold'
                        >
                            {delbtnloader[row.original.id] ? (
                                <>
                                    <span>Deleting</span>
                                    <ArrowPathIcon className='animate-spin w-[15px] h-[15px]' />
                                </>
                            ) : (
                                <>
                                    <span>Delete</span>
                                    <TrashIcon className='w-[15px] h-[15px]' />
                                </>
                            )}
                        </button>
                    </Box>
                )
            },
        ],
        [delbtnloader],
    );

    const getAllSubscribers = async () => {
        try {
            const response = await axiosInstance.get(`${`/groups/${g_id}/subscribers`}`);
            setSubscribersData(response.data.subscribers);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditSubscriber = (subscriber) => {
        setFormData(subscriber);
        setCurrentId(subscriber.id);
        setIsEditMode(true);
        setOpenModal(true);
    };

    const handleDeleteSubscriber = async (id, email) => {
        try {
            setDelbtnloader(prev => ({ ...prev, [id]: true }));
            const resp = await axiosInstance.get(`/gophish_groups/${g_id}`);
            const ggId = resp.data.gophish_groupid;

            const groupResponse = await axios.get(`${GoPhishPublicURL}/api/groups/${ggId}/?api_key=${GoPhishAccountAPIKey}`);
            const groupData = groupResponse.data;

            await axiosInstance.delete("/subscribers/del", { data: { id: id } });
            toast.success("Subscriber deleted successfully!");

            groupData.targets = groupData.targets.filter(target => target.email !== email);

            const updateResponse = await axios.put(`${GoPhishPublicURL}/api/groups/${ggId}/?api_key=${GoPhishAccountAPIKey}`, groupData);

            if (updateResponse.status === 200) {
                toast.success("Group updated successfully after deleting subscriber!");
            } else {
                toast.error("Failed to update the group after deleting subscriber!");
            }
            getAllSubscribers();
        } catch (error) {
            console.log(error);
            toast.error("Error deleting Subscriber!");
        } finally {
            setDelbtnloader(prev => ({ ...prev, [id]: false }));
        }
    };

    const handleAddSubscriber = () => {
        if (g_id) {
            setFormData({ group_id: g_id, firstname: '', lastname: '', email: '' });
            setIsEditMode(false);
            setOpenModal(true);
        }
        else {
            setEmptyGroupSelError(true)
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setFormData({ firstname: '', lastname: '', email: '' });
        setCurrentId(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isEditMode) {
            try {
                await axiosInstance.put(`/subscribers/update`, {
                    id: currentId,
                    ...formData,
                });
                toast.success("Subscriber updated successfully!");
            } catch (error) {
                console.log(error);
                toast.error("Error updating Subscriber!");
            }
        } else {
            try {
                const response = await axiosInstance.post(`/subscribers/add`, { ...formData, group_id: g_id, currentUser });

                if (response.data.success === false && response.data.zeroSeatCourses) {
                    return toast.error(`Error: ${response.data.message} ==> ${JSON.stringify(response.data.zeroSeatCourses)}`);
                }
                else if (response.data.success === false && response.data.zeroSeatCourses === undefined) {
                    return toast.error(`Error: ${response.data.message}`);

                } else if (response.data.success === true) {
                    toast.success("Subscriber added successfully!");
                }

                const resp = await axiosInstance.get(`/gophish_groups/${g_id}`);
                const ggId = resp.data.gophish_groupid;

                const groupResponse = await axios.get(`${GoPhishPublicURL}/api/groups/${ggId}/?api_key=${GoPhishAccountAPIKey}`);
                const groupData = groupResponse.data;

                const newTarget = {
                    email: formData.email,
                    first_name: formData.firstname,
                    last_name: formData.lastname,
                    position: "Subscriber"
                };

                groupData.targets.push(newTarget);

                const updateResponse = await axios.put(`${GoPhishPublicURL}/api/groups/${ggId}/?api_key=${GoPhishAccountAPIKey}`, groupData);

                if (updateResponse.status === 200) {
                    toast.success("Group updated successfully with new subscriber!");
                } else {
                    toast.error("Failed to update the group!");
                }
            } catch (error) {
                console.log(error);
                toast.error("Error adding Subscriber!");
            }
        }
        handleCloseModal();
        getAllSubscribers();
    };

    useEffect(() => {
        if (g_id) {
            getAllSubscribers();
        }
        setEmptyGroupSelError(g_id ? false : true)
    }, [g_id]);

    return (
        <>
            <div className="w-[90%] mt-[50px] flex flex-col gap-5 justify-center items-start">
                {EmptyGroupSelError && (<h3 className='text-red-600 font-semibold'>Select a group first!</h3>)}
                <Button variant="outlined" onClick={handleAddSubscriber}>
                    Add Subscriber
                </Button>
                <h1 className="text-black font-bold text-[25px] mt-5 mb-5">All Subscribers</h1>
                {SubscribersData && (<p className='font-semibold'>{SubscribersData?.length} Subscriber(s)</p>)}
                <div className='w-full'>
                    <MaterialReactTable
                        columns={SubscriberColumns}
                        data={SubscribersData}
                    />
                </div>
            </div>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                }}
                fullWidth
            >
                <DialogTitle sx={{ marginBottom: "20px", backgroundColor: "#00486f", color: "white", fontWeight: "bold" }}>{isEditMode ? 'Edit Subscriber' : 'Add Subscriber'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.firstname}
                        onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                    />
                </DialogContent>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="lastname"
                        name="lastname"
                        label="Last Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.lastname}
                        onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                    />
                </DialogContent>
                <DialogContent>
                    <TextField
                        disabled={isEditMode}
                        autoFocus
                        required
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button type="submit">{isEditMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Subscriber
import React, { forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CampaignAnalyticsDialog = ({ open, handleClose, stats }) => {
	return (
		<>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
				maxWidth="lg"
			>
				<DialogTitle sx={{ fontWeight: 800 }}>Statistical analysis</DialogTitle>
				{stats && (
					<DialogContent>
						<div className="flex flex-row justify-center items-center flex-wrap space-x-6">
							<div className="flex flex-col items-center">
								<span className="mb-2 text-sm font-semibold text-gray-700">
									Email Sent
								</span>
								<div className="flex items-center justify-center w-24 h-24 hover:border-[8px] transition-all delay-75 ease-in-out cursor-pointer border-4 border-teal-500 rounded-full bg-transparent">
									<span className="text-teal-500 text-xl font-bold">
										{stats.sent}
									</span>
								</div>
							</div>

							<div className="flex flex-col items-center">
								<span className="mb-2 text-sm font-semibold text-gray-700">
									Email Opened
								</span>
								<div className="flex items-center justify-center w-24 h-24 hover:border-[8px] transition-all delay-75 ease-in-out cursor-pointer border-4 border-yellow-400 rounded-full bg-transparent">
									<span className="text-yellow-400 text-xl font-bold">
										{stats.opened}
									</span>
								</div>
							</div>

							<div className="flex flex-col items-center">
								<span className="mb-2 text-sm font-semibold text-gray-700">
									Clicked Link
								</span>
								<div className="flex items-center justify-center w-24 h-24 hover:border-[8px] transition-all delay-75 ease-in-out cursor-pointer border-4 border-orange-400 rounded-full bg-transparent">
									<span className="text-orange-400 text-xl font-bold">
										{stats.clicked}
									</span>
								</div>
							</div>

							<div className="flex flex-col items-center">
								<span className="mb-2 text-sm font-semibold text-gray-700">
									Submitted Data
								</span>
								<div className="flex items-center justify-center w-24 h-24 hover:border-[8px] transition-all delay-75 ease-in-out cursor-pointer border-4 border-red-500 rounded-full bg-transparent">
									<span className="text-red-500 text-xl font-bold">
										{stats.submitted_data}
									</span>
								</div>
							</div>

							<div className="flex flex-col items-center">
								<span className="mb-2 text-sm font-semibold text-gray-700">
									Email Reported
								</span>
								<div className="flex items-center justify-center w-24 h-24 hover:border-[8px] transition-all delay-75 ease-in-out cursor-pointer border-4 border-blue-400 rounded-full bg-transparent">
									<span className="text-blue-400 text-xl font-bold">
										{stats.email_reported}
									</span>
								</div>
							</div>

							<div className="flex flex-col items-center">
								<span className="mb-2 text-sm font-semibold text-gray-700">
									Error
								</span>
								<div className="flex items-center justify-center w-24 h-24 hover:border-[8px] transition-all delay-75 ease-in-out cursor-pointer border-4 border-red-700 rounded-full bg-transparent">
									<span className="text-red-700 text-xl font-bold">
										{stats.error ? stats.error : 0}
									</span>
								</div>
							</div>
						</div>
					</DialogContent>
				)}
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CampaignAnalyticsDialog;

import React, { useState } from 'react';
import axiosInstance from '../../../utils/axiosConfig';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';

const SubscriberOTPLogin = () => {
  const navigate = useNavigate();
  const { login } = useAuth()

  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSendOtp = async () => {
    try {
      const response = await axiosInstance.post('/v1/auth/pwdless/subscriber_loginotp', { email });
      if (response.status === 200 && response.data.success === true) {
        setIsOtpSent(true);
        setMessage('OTP sent successfully!');
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage('Error sending OTP. Please try again.');
      console.error('Error sending OTP:', error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axiosInstance.post('/otp/verify-pwdless-otp', {
        email,
        otpCode: otp,
        userType: 'subscriber'
      });
      if (response.status === 200 && response.data.success) {
        setMessage('Successfully logged in!');
        login(response.data?.user)
        navigate("/subscriber_dashboard")
      } else {
        setMessage(response.data.message || 'Invalid OTP or OTP expired.');
      }
    } catch (error) {
      setMessage('Error verifying OTP. Please try again.');
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <>
      <div className='w-full bg-black flex flex-col justify-center items-center min-h-screen'>
        <h2 className='text-white text-2xl font-semibold mb-4'>Subscriber OTP Login</h2>
        {!isOtpSent && (
          <div className="flex flex-col items-center">
            <input
              className="border border-gray-300 rounded-md px-4 py-2 mb-2 w-64 focus:outline-none focus:border-blue-500"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
              onClick={handleSendOtp}
            >
              Send OTP
            </button>
          </div>
        )}
        {isOtpSent && (
          <div className="flex flex-col items-center mt-4">
            <input
              className="border border-gray-300 rounded-md px-4 py-2 mb-2 w-64 focus:outline-none focus:border-blue-500"
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOtpChange}
              required
            />
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
              onClick={handleVerifyOtp}
            >
              Verify & Login
            </button>
          </div>
        )}
        {message && <p className="text-red-500 mt-2">{message}</p>}
      </div>
    </>
  );
};

export default SubscriberOTPLogin;


import React, { useState, useEffect } from 'react'
import axiosInstance from '../../utils/axiosConfig';
import courseicon from "../../../src/assets/course.png"
import { Link } from "react-router-dom"

const BuyNewLicense = () => {
    const [coursesData, setCoursesData] = useState([]);
    const getAllCourses = async () => {
        try {
            const result = await axiosInstance.get("/courses/all");
            const courses = result.data.data
            setCoursesData(courses)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAllCourses()
    }, [])
    return (
        <>
            <div className="w-full min-h-screen flex flex-col bg-[#FAFAFA] justify-start items-center">

                <div className="w-[90%] flex flex-col gap-5 justify-center items-center">

                    <div className='w-full flex flex-col justify-center items-start'>
                        <h1 className="text-[#00486F] text-start font-bold text-[30px] mt-5 mb-5">Shop</h1>
                    </div>

                    <div className='w-full grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 justify-center gap-2 items-center flex-wrap'>
                        {coursesData && coursesData.map((course, index) => (
                            <CourseCard key={index} data={course} />
                        ))}
                    </div>

                    {coursesData.length === 0 && (
                        <h2 className='text-black text-center font-bold text-1xl'>No Courses Available!</h2>
                    )}

                </div>

            </div>
        </>
    )
}

export const CourseCard = ({ data }) => {
    return (
        <>
            <div className="w-full bg-white border border-gray-200 rounded-lg shadow">

                <img className="rounded-t-lg cursor-not-allowed w-full" src={courseicon} alt="" />

                <div className="p-5">

                    <h5 className="mb-2 text-[20px] truncate font-bold tracking-tight text-gray-900">{data.title}</h5>

                    <p className="mb-3 font-normal text-gray-900 truncate">{data.description}</p>
                    <p className="mb-3 font-normal text-gray-900 truncate">${data.priceperseat} / Seat</p>
                    <div className='w-full flex flex-col justify-center items-center'>
                        <Link to={`/dashboard/mycart?course_id=${data.id}`}>
                            <div className="cursor-pointer inline-flex items-center px-5 py-2 text-md font-bold text-center text-white bg-[#02496F] rounded-[22px] hover:bg-blue-800">
                                Add to Cart
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuyNewLicense
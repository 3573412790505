import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosConfig';
import Spinner from '../components/Spinner';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { CircularProgress } from '@mui/material'

const GettingStarted = () => {
    const navigate = useNavigate()
    const [CourseData, setCourseData] = useState(null);
    const [Loader, setLoader] = useState(true);
    const { currentUser } = useAuth();
    const [buttonLoader, setbuttonLoader] = useState(false)

    const getCourseDetailsForUser = async (course_id, currentUser) => {
        try {
            setLoader(true);
            const resp = await axiosInstance.get(`/course_details/progress/fetch?user_email=${currentUser.email}&user_role=${currentUser.role}&course_id=${course_id}`);

            if (resp.data.status === 404) {
                const response = await axiosInstance.get(`/courses/all_course_details/${course_id}`);
                setCourseData(response.data.data);
            }
            else if (resp.data.status === 200) {
                navigate(`${currentUser.role === "groupleader" ? "/groupleader_dashboard" : "/subscriber_dashboard"}/view_course?course_id=${course_id}`)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const handleMarkCourseAsStarted = async () => {
        try {
            setbuttonLoader(true);
            const resp = await axiosInstance.post(`/course_details/status/start`, {
                course_id: CourseData.id,
                user_id: currentUser.id,
                user_email: currentUser.email,
                user_role: currentUser.role
            });

            setTimeout(() => {
                if (resp.data.status === 200) {
                    navigate(`${currentUser.role === "groupleader" ? "/groupleader_dashboard" : "/subscriber_dashboard"}/view_course?course_id=${CourseData.id}`);
                }
                setbuttonLoader(false);
            }, 3000);

        } catch (error) {
            console.log(error);
            setbuttonLoader(false);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const course_id = params.get("course_id");
        if (course_id && currentUser) {
            getCourseDetailsForUser(course_id, currentUser);
        } else {
            setLoader(false);
        }
    }, []);

    return (
        <div className='w-full flex flex-col justify-center items-center min-h-screen'>
            {Loader ? (
                <Spinner />
            ) : (
                <div className="my-10 px-5 container mx-auto flex flex-col md:flex-row shadow-lg rounded-lg overflow-hidden">
                    {/* Left Section */}
                    <div className="relative flex-1 py-12 md:py-24 bg-blue-700 flex flex-col items-center justify-center text-center text-white">
                        <div className="absolute top-0 left-0 z-10 w-16 h-16 md:w-40 md:h-40 md:ml-20 md:mt-24 opacity-50">
                            {/* Placeholder for background graphics */}
                        </div>
                        <div className="relative text-3xl md:text-5xl py-6 px-6 md:py-8 md:px-12 font-semibold leading-tight tracking-tight mb-4 z-20">
                            <span className="block">Welcome to</span>
                            <span className="block">Our Course!</span>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="flex-1 bg-gray-100 flex flex-col">
                        <div className="flex-1 flex flex-col justify-center p-6 md:p-16">
                            <p className="text-gray-700 serif font-normal text-lg md:text-xl">
                                Get ready to embark on an exciting learning journey! This course will guide you through the fundamentals and advanced concepts of [Course Topic]. Our step-by-step approach ensures that you gain practical skills and knowledge.
                            </p>
                        </div>
                        <div className="p-6 md:p-16 flex flex-col items-center">
                            {CourseData && (
                                <>
                                    <div className="text-center">
                                        <h2 className="text-lg md:text-xl font-bold text-gray-800 leading-tight">{CourseData.title}</h2>
                                        <small className="text-gray-600 text-sm md:text-base">{CourseData.description.slice(0, 30)}...</small>
                                    </div>
                                    <div className="mt-6 flex justify-center">
                                        <button
                                            onClick={handleMarkCourseAsStarted}
                                            className="rounded-full flex flex-row justify-center items-center border bg-blue-600 text-white focus:outline-none gap-2 hover:bg-blue-700 font-semibold px-3 py-2"
                                        >
                                            {buttonLoader ?
                                                (
                                                    <>
                                                        Redirecting
                                                        <CircularProgress color="inherit" size={15} />
                                                    </>
                                                ) :
                                                (
                                                    "Get Started!"
                                                )
                                            }
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GettingStarted;
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../AuthContext';
import Header from './components/Header';

const Loader = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 opacity-75 z-50">
            <div className="w-[50%] bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                <div className="bg-gray-600 w-[45%] h-2.5 rounded-full dark:bg-gray-300 animate-move"></div>
            </div>
        </div>
    );
};

const WebLayout = () => {
    const { currentUser, loading, userRole } = useAuth()

    if (loading) {
        return <Loader />
    }

    return (
        currentUser && userRole === "admin" ? (
            <Navigate to="/dashboard" />
        ) : currentUser && userRole === "contributor" ? (
            <Navigate to="/contrib_dashboard" />
        )
            : currentUser && userRole === "groupleader" ? (
                <Navigate to="/groupleader_dashboard" />
            )
                : currentUser && userRole === "subscriber" ? (
                    <Navigate to="/subscriber_dashboard" />
                )
                    : (
                        <>
                            <Header />
                            <div>
                                <Outlet />
                            </div>
                        </>
                    )
    );
}

export default WebLayout
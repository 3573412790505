export function maskEmail(email) {
    return email.replace(/([^@])[^\@]*(?=@)(@.+)/, '$1***$2');
}

export function generatePassword(length) {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const specialChars = '!@#$%^&*()_+{}[]|:;<>,.?/';
    const numbers = '0123456789';

    const allChars = lowercaseChars + uppercaseChars + specialChars + numbers;
    let password = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        password += allChars[randomIndex];
    }

    return password;
}
